import {
	UserPreferencesGeneral,
	UserPreferencesMap,
	UserPreferencesNode,
	UserPreferencesVisualizer,
	UserPreferencesAI,
} from '../user';

export interface SettingsResponse {
	general: UserPreferencesGeneral;
	visualizer: UserPreferencesVisualizer;
	map: UserPreferencesMap;
	node: UserPreferencesNode;
	ai: UserPreferencesAI;
}
